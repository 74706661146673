@use "../../01-settings/colors" as color;
@use "../../01-settings/measurements" as measurement;
@use "../../02-tools/container" as container;
@use "../../02-tools/articles" as article;
@use "../../02-tools/images-and-icons" as image;
@use "../../02-tools/circles" as circle;
@use "../../02-tools/blobs" as blobs;
@use "../../02-tools/typography" as typography;

.footer {
    @include image.logo-settings;
    //@include circle.display-settings;
    @include image.background-lines;
    position: relative;

    &__inner {
        @include container.standard-settings(true);
    }

    &__article {
        @include article.standard-settings(true, true);
        text-align: center;
    }

    &__circle {
        &:nth-of-type(1) {
            right: -18vw;
            top: 25vh;
        }

        &:nth-of-type(2) {
            left: -20vw;
            top: 70vh;
        }
    }

    &__navigation {
        display: flex;
        flex-wrap: wrap;
        gap: measurement.$universal-space-2;

        li {
            width: 100%;
            text-align: center;
        }


        a {
            @include typography.headline-h5;
            color: color.$c-white;
            text-decoration: none;


            &:hover {
                color: color.$c-pink;
            }
        }
    }

    &__copyright {
        position: absolute;
        bottom: measurement.$universal-space-2;
        width: 100%;
        text-align: center;
    }

    &__background-lines {
        rotate: 90deg;
    }
}
