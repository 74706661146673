$content-max-width: 72rem;
$resource-max-width: 72rem;
$modal-max-width: 62rem;
$address-max-width: 52rem;
$universal-space: 1.5rem;
$universal-space-half: calc(#{$universal-space} / 2);
$universal-space-2: calc(#{$universal-space} * 2);
$universal-space-3: calc(#{$universal-space} * 3);
$universal-space-4: calc(#{$universal-space} * 4);
$universal-space-5: calc(#{$universal-space} * 5);
$universal-space-6: calc(#{$universal-space} * 6);
$universal-space-7: calc(#{$universal-space} * 7);
$universal-space-8: calc(#{$universal-space} * 8);
$universal-space-9: calc(#{$universal-space} * 9);
$universal-space-10: calc(#{$universal-space} * 10);
$universal-space-11: calc(#{$universal-space} * 11);
$universal-space-12: calc(#{$universal-space} * 12);
$section-standard-max-width: 142rem;

