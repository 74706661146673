@use "../01-settings/measurements" as measurement;

@mixin logo-settings() {
    &__logo-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    &__logo {
        max-width: 15rem;
    }
}


@mixin image-settings {
    &__image-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        overflow: hidden;

        img, svg {
            width: 100%;
            height: auto;
            max-width: 350px;
            object-fit: contain;
        }

        &--full {
            overflow: hidden;
            margin-top: measurement.$universal-space-3;
        }
    }
}

@mixin background-lines {
    &__background-lines {
        width: 1000%;
        transform: translateY(-50%);
        position: absolute;
        left: -527%;
        right: 0;
        margin: 0 auto;
        object-fit: cover;
        opacity: 0.2;
        z-index: 1;
        pointer-events: none;
    }
}
