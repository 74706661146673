@use "../../01-settings/abstracts.scss" as abstract;
@use "../../01-settings/colors.scss" as color;
@use "../../01-settings/measurements" as measurement;
@use "../../02-tools/typography" as typography;
@use "../../02-tools/container" as container;
@use "../../02-tools/articles" as article;
@use "../../02-tools/breakpoints" as breakpoint;
@use "../../02-tools/blobs" as blobs;
@use "../../02-tools/images-and-icons" as image;
@use "../../02-tools/navigation" as navigation;
@use "../../02-tools/circles" as circle;

.cache {
    @include image.logo-settings;
    @include image.image-settings;
    @include blobs.display-settings;
    @include navigation.styling;
    @include circle.display-settings;
    @include image.background-lines;
    position: relative;

    &__inner {
        @include container.standard-settings(true);
    }

    &__article {
        @include article.standard-settings(true, true);
        text-align: center;
    }

    &__blob {
        &:nth-of-type(1) {
            top: 30vh;
            left: -20vw;
        }

        &:nth-of-type(2) {
            right: -17vw;
            top: 50vh;
        }

        &:nth-of-type(3) {
            right: 30vw;
            top: 70vh;
        }
    }

    &__circle {
        &:nth-of-type(1) {
            right: -18vw;
            top: 25vh;
        }

        &:nth-of-type(2) {
            left: -20vw;
            top: 70vh;
        }

        &:nth-of-type(3) {
            top: 10vh;
        }
    }

    &__image {
        transform: translate3d(100%, 50%, 0);
        transition: abstract.$slow-transition;
    }

    &.active {
        img.cache__image {
            transform: translate3d(15%, 0%, 0);
        }
    }

    &__background-lines {
        left: -594%;
        rotate: 107deg;

    }
}
