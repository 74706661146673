@use "../01-settings/measurements" as measurement;

@mixin display-settings() {
    &__circle {
        position: absolute;
        width: measurement.$universal-space-12;
        height: measurement.$universal-space-12;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.8;
        pointer-events: none;
        background-image: url("../../svg/circle.svg");

        &--large {
            width: measurement.$universal-space-12;
            height: measurement.$universal-space-12;
            animation: moveInSpace 30s linear infinite;
        }

        &--small {
            width: measurement.$universal-space-8;
            height: measurement.$universal-space-8;
            animation: moveInSpace 30s linear infinite;
        }

    }
}


@keyframes moveInSpace {
    0% {
        transform: translateX(0) translateY(-100px);
    }
    25% {
        transform: translateX(50px) translateY(0);
    }
    50% {
        transform: translateX(0) translateY(50px);
    }
    75% {
        transform: translateX(-50px) translateY(0);
    }
    100% {
        transform: translateX(0) translateY(-50px);
    }
}
