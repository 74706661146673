@use "../../01-settings/_colors.scss" as color;
@use "../../02-tools/typography" as typography;

#fp-nav {
    right: 5px !important;

    span {
        background-color: color.$c-white !important;
    }
}
