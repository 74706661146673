@use "../01-settings/abstracts" as abstract;
@use "../01-settings/measurements" as measurement;
@use "../01-settings/colors" as color;
@use "./typography" as typography;

@mixin standard-settings($max-width: false, $center: false) {
    > :first-child {
        margin-top: 0 !important;
    }
    > :last-child {
        margin-bottom: 0 !important;
    }
    @if $max-width {
        max-width: measurement.$content-max-width;
    }
    @if $center {
        margin: 0 auto;
    }
}

