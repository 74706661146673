@use "../01-settings/measurements" as measurement;
@use "./breakpoints" as breakpoint;

@mixin standard-settings($max-width: false) {
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding: 0 measurement.$universal-space-2;
    z-index: 3;
    @if $max-width {
        max-width: measurement.$section-standard-max-width;
    }
}
