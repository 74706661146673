@use "../../01-settings/colors" as color;
@use "../../01-settings/measurements" as measurement;
@use "../../02-tools/container" as container;
@use "../../02-tools/images-and-icons" as image;

.header {
    @include image.logo-settings;
    position: fixed;
    top: 0;
    width: 100%;
    padding: measurement.$universal-space-2 0;
    z-index: 1;

    &__inner {
        @include container.standard-settings(true);
    }


}
