@use "../01-settings/measurements" as measurement;
@use "../01-settings/colors.scss" as color;
@use "./breakpoints" as breakpoint;

@mixin styling {
    &__navigation {
        display: flex;
        position: absolute;
        bottom: measurement.$universal-space-2;
        width: 100%;
        z-index: 3;

        li {
            flex: 1;
            text-align: center;

            a {
                text-decoration: none;
                color: inherit;

                &:hover {
                    color: color.$c-pink;
                }
            }
        }
    }
}
