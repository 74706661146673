@use "../01-settings/measurements" as measurement;
@use "./breakpoints" as breakpoint;

@mixin block-spacing-margin() {
    margin: measurement.$universal-space-3 0;
    @include breakpoint.large-screen-min {
        margin: measurement.$universal-space-6 0;
    }
}

@mixin block-spacing-padding() {
    padding: measurement.$universal-space-3 0;
    @include breakpoint.large-screen-min {
        padding: measurement.$universal-space-6 0;
    }
}

@mixin block-overlay-spacing-padding() {
    padding: measurement.$universal-space-3 measurement.$universal-space-2;
    @include breakpoint.large-screen-min {
        padding: measurement.$universal-space-6 measurement.$universal-space-2;
    }
}

@mixin section-spacing() {
    &:not(.background) {
        @include block-spacing-margin;
    }
    &.background {
        @include block-spacing-padding;
    }
}

@mixin group-spacing() {
    margin-top: measurement.$universal-space-2;
    margin-bottom: measurement.$universal-space-2;
    @include breakpoint.large-screen-min {
        margin-top: measurement.$universal-space-4;
        margin-bottom: measurement.$universal-space-4;
    }
}

@mixin spacing-bottom() {
    margin-bottom: measurement.$universal-space-2;
    @include breakpoint.large-screen-min {
        margin-bottom: measurement.$universal-space-4;
    }
}

@mixin spacing-top() {
    margin-top: measurement.$universal-space-2;
    @include breakpoint.large-screen-min {
        margin-top: measurement.$universal-space-4;
    }
}

@mixin padding-bottom() {
    padding-bottom: measurement.$universal-space-2;
    @include breakpoint.large-screen-min {
        padding-bottom: measurement.$universal-space-4;
    }
}

@mixin padding-top() {
    padding-top: measurement.$universal-space-2;
    @include breakpoint.large-screen-min {
        padding-top: measurement.$universal-space-4;
    }
}

@mixin paragraph-spacing {
    margin-top: measurement.$universal-space;
    margin-bottom: measurement.$universal-space;
}

@mixin headline-spacing {
    margin-top: measurement.$universal-space-2;
    margin-bottom: measurement.$universal-space;
}

@mixin list-spacing {
    margin-bottom: measurement.$universal-space;
    &:last-of-type {
        margin-bottom: 0;
    }
}
