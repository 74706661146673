@use "../../01-settings/colors.scss" as color;
@use "../../01-settings/measurements" as measurement;
@use "../../02-tools/typography" as typography;
@use "../../02-tools/container" as container;
@use "../../02-tools/articles" as article;
@use "../../02-tools/breakpoints" as breakpoint;
@use "../../02-tools/blobs" as blobs;
@use "../../02-tools/images-and-icons" as image;
@use "../../02-tools/navigation" as navigation;
@use "../../02-tools/circles" as circle;

.jet {
    @include image.logo-settings;
    @include image.image-settings;
    @include blobs.display-settings;
    @include navigation.styling;
    @include circle.display-settings;
    @include image.background-lines;

    &__inner {
        @include container.standard-settings(true);
    }

    &__article {
        @include article.standard-settings(true, true);
        text-align: center;

        h2 {
            text-shadow: 2px 2px color.$c-black;

            span {
                color: color.$c-yellow;
            }
        }
    }

    &__logo {
        max-width: 8rem;
    }


    &__blob {
        &:nth-of-type(1) {
            top: 70vh;
            left: -20vw;
        }

        &:nth-of-type(2) {
            right: -17vw;
            top: 0;
        }

        &:nth-of-type(3) {
            right: -17vw;
            top: 25vh;
        }

        &:nth-of-type(4) {
            left: -10vw;
            top: 25vh;
        }
    }

    &__circle {
        &:nth-of-type(1) {
            left: -18vw;
            top: 0vh;
        }

        &:nth-of-type(2) {
            right: -20vw;
            top: 80vh;
        }

    }

    &__background-lines {
        left: -460%;
    }

}
