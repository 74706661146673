@use "../01-settings/typography" as typography;
@use "../01-settings/abstracts" as abstract;
@use "../01-settings/measurements" as measurement;
@use "../01-settings/colors" as color;
@use "../02-tools/breakpoints" as *;

@mixin font-size($min-size-multiplier, $max-size-multiplier) {
    font-size: clamp(
        calc(#{typography.$font-base-size} * #{$min-size-multiplier}),
        -0.875rem + 8.333vw,
        calc(#{typography.$font-base-size} * #{$max-size-multiplier})
    );
}

@mixin headline-settings {
    font-weight: typography.$font-headline-font-weight;
    line-height: typography.$font-headline-line-height;
    font-family: typography.$font-headline;
}

@mixin base-settings {
    @include font-size(0.6, 1);
    font-weight: typography.$font-base-font-weight;
    line-height: typography.$font-base-line-height;
    font-family: typography.$font-main;
}

@mixin super-size {
    @include font-size(2, 3);
}

@mixin headline-h1 {
    @include font-size(1.8, 2.8);
}

@mixin headline-h2 {
    @include font-size(1.6, 2.6);
}

@mixin headline-h3 {
    @include font-size(1.4, 2.4);
}

@mixin headline-h4 {
    @include font-size(1.2, 2.2);
}

@mixin headline-h5 {
    @include font-size(1, 2);
}

@mixin headline-h6 {
    @include font-size(0.8, 1.6);
}

@mixin paragraph-settings {
    @include base-settings;
}

@mixin font-large {
    @include font-size(1, 1.2);
}

@mixin font-small {
    @include font-size(0.6, 0.8);
}
