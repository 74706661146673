@use "../01-settings/colors.scss" as color;
@use "../01-settings/measurements" as measurement;

@use "./breakpoints" as breakpoint;

@mixin display-settings() {
    &__blob {
        position: absolute;
        width: measurement.$universal-space-12;
        height: measurement.$universal-space-12;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-blend-mode: screen;
        background-color: color.$c-dark-purple;
        opacity: 0.6;
        pointer-events: none;

        &--purple {
            background-image: url("../../svg/blob--purple.svg");
            animation: floatPulse 4s infinite alternate ease-in-out;

        }

        &--blue {
            background-image: url("../../svg/blob--blue.svg");
            animation: floatPulse 3s infinite alternate ease-in-out;
        }
    }
}

@keyframes floatPulse {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0.8;
    }
    50% {
        transform: translateY(-20px) scale(1.2);
        opacity: 1;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 0.8;
    }
}

