@use "../../01-settings/abstracts.scss" as abstract;
@use "../../01-settings/colors.scss" as color;
@use "../../01-settings/measurements" as measurement;
@use "../../02-tools/typography" as typography;
@use "../../02-tools/container" as container;
@use "../../02-tools/articles" as article;
@use "../../02-tools/breakpoints" as breakpoint;
@use "../../02-tools/blobs" as blobs;
@use "../../02-tools/circles" as circle;


.intro {
    @include blobs.display-settings;
    //@include circle.display-settings;
    background-image: url("../../../svg/globe.svg");
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center 70vh;
    position: relative;

    &__background-lines {
        position: absolute;
        top: -15vh;
        width: 500vw;
        left: -200vw;
        object-fit: contain;
        max-height: 100vh;
        z-index: 2;
        opacity: 0.3;
        @include breakpoint.large-screen-min {
            max-height: 100vh;
            top: -50vh;
        }
    }

    &__inner {
        @include container.standard-settings(true);
    }

    &__article {
        @include article.standard-settings(true, true);
        text-align: center;
    }

    &__scroll-down {
        display: inline-block;
        width: measurement.$universal-space-2;
        height: measurement.$universal-space-4;
        border: 1px solid color.$c-white;
        border-radius: 60px;
        position: fixed;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: measurement.$universal-space-2;
        overflow: hidden;


        &::before {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            background-color: color.$c-white;
            border-radius: 50%;
            opacity: 1;
            animation: wheel 2s infinite;
            -webkit-animation: wheel 2s infinite;
        }
    }

    &__blob {
        &:nth-of-type(1) {
            top: 10vh;

        }

        &:nth-of-type(2) {
            right: -17vw;
            top: 15vh;
        }

        &:nth-of-type(3) {
            right: -20vw;
            top: 40vh;

        }

        &:nth-of-type(4) {
            left: -5vw;
            top: 45vh;

        }
    }

    &__circle {
        &:nth-of-type(1) {
            left: -18vw;
            top: 25vh;
        }

        &:nth-of-type(2) {
            right: -10vw;
            top: 60vh;
        }

    }

}

@keyframes wheel {
    to {
        opacity: 0;
        top: 60px;
    }
}

@-webkit-keyframes wheel {
    to {
        opacity: 0;
        top: 60px;
    }
}
