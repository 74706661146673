@use "../../01-settings/colors.scss" as color;
@use "../../01-settings/measurements" as measurement;
@use "../../02-tools/typography" as typography;
@use "../../02-tools/container" as container;
@use "../../02-tools/articles" as article;
@use "../../02-tools/breakpoints" as breakpoint;
@use "../../02-tools/blobs" as blobs;
@use "../../02-tools/images-and-icons" as image;
@use "../../02-tools/navigation" as navigation;
@use "../../02-tools/circles" as circle;

.visiblu {
    @include image.logo-settings;
    @include blobs.display-settings;
    @include navigation.styling;
    @include image.image-settings;
    position: relative;
    background-image: url("../../../svg/visiBlu_Patter.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 60vh;

    &__inner {
        @include container.standard-settings(true);
    }

    &__article {
        @include article.standard-settings(true, true);
        text-align: center;

        h2 {
            span {
                color: color.$c-blue;
            }
        }
    }


    &__blob {
        &:nth-of-type(1) {
            top: 35vh;
            left: -20vw;
        }

        &:nth-of-type(2) {
            right: -17vw;
            top: 25vh;
            display: none;

        }

        &:nth-of-type(3) {
            right: -17vw;
            top: 15vh;
        }

        &:nth-of-type(4) {
            left: -5vh;
            top: 0;
        }
    }

    &__bottom {
        position: absolute;
        bottom: measurement.$universal-space-2;
        left: measurement.$universal-space;
        text-align: center;
        width: 100%;
        margin: 0;
    }


    &__probe {
        #Path_87 {
            stroke-dasharray: 1670;
            stroke-dashoffset: 1670;
        }

        #Path_88 {
            stroke-dasharray: 1420;
            stroke-dashoffset: 1420;

        }
    }

    &.active {
        #Path_88 {
            animation: dash 2s linear forwards;
        }

        #Path_87 {
            animation: dash-2 2s linear forwards;
        }
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 1420;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash-2 {
    from {
        stroke-dashoffset: 1670;
    }
    to {
        stroke-dashoffset: 0;
    }
}
