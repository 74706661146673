@use "../../01-settings/_colors.scss" as color;
@use "../../02-tools/typography" as typography;

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}


body {
    @include typography.base-settings;
    background-color: color.$c-dark-purple;
    color: color.$c-white;
}
