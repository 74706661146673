@import url("https://use.typekit.net/hzd8bov.css");

@font-face {
    font-family: 'tofinobold_personal';
    src: url('../../fonts/tofino-boldpersonal-webfont.woff2') format('woff2'),
    url('../../fonts/tofino-boldpersonal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


$font-main: "proxima-nova", sans-serif;
$font-headline: 'tofinobold_personal', sans-serif;
$font-reset: 62.5%;
$font-base-size: 1.8rem;
$font-base-line-height: 1.6;
$font-headline-line-height: 1.4;
$font-base-font-weight: 400;
$font-sub-font-weight: 700;
$font-headline-font-weight: 700;
